<template>
  <main v-if="!registerForCode" class="pt-8">
    <div class="flex flex-col gap-4 items-center my-3">
      <h2 class="font-bold text-lg text-dark-800">Pension Registration</h2>
    </div>

    <form @submit.prevent="registerPension" autocomplete="on" class="flex flex-col text-left">
      <div class="px-3 md:px-8 lg:px-4 xl:px-6">
        <div class="w-full flex flex-col mb-5">
          <!-- <label for="employerCode" class="text-dark-800 text-sm font-medium"
            >Enter Employer Code
          </label> -->
          <easiTextInput
            placeholder="Employer Code"
            type="text"
            name="employerCode"
            class="mt-1 mb-4"
            v-model="args.pensionCode"
            required
          ></easiTextInput>

          <div
            @click="
              () => {
                registerForCode = true;
              }
            "
            class="cursor-pointer w-56 mx-auto border border-outlineGray rounded-full p-2 text-primary font-medium shadow-sm text-xs mt-5 text-center"
          >
            Don’t have? Get an employer code
          </div>
        </div>
        <hr class="mb-3 border-0.5 border-dividerColor text-dividerColor" />
      </div>

      <div class="flex flex-col gap-2 px-3 md:px-8 lg:px-4 xl:px-6">
        <p for="email" class="text-dark-600 text-sm font-bold text-center">Add employee to register</p>
        <easiEmployeeBonus
          @update="assignId($event)"
          :value="employeeIds"
          :options="createEmpList()"
          :single="true"
          :directorView="true"
          class="mt-1"
          :placeholder="empName"
        />

        <div
          class="py-2 px-4 border w-full border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-4">
            <div class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0">
              <img src="@/assets/icons/img-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <div v-if="uploadFile && uploadFile.name" class="text-center w-9/12 font-bold text-sm flex-col leading-4">
                {{ uploadFile.name }}
              </div>

              <span v-else class="mb-2 text-xs leading-5 font-bold">Upload employee passport photo</span>
            </div>
          </div>

          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :imageLink="args.passportUrl"
              :loop="true"
              @fileUrl="uploadFile = $event"
            />
          </span>
        </div>
        <!-- <div class="justify-center mb-7">
          <UploadComponent
            @fileUrl="uploadFile = $event"
            :imageLink="args.passportUrl"
            :profile="true"
            :fullWidth="true"
          />
        </div> -->

        <!-- <label for="nin" class="text-dark-800 text-sm font-medium">NIN </label> -->
        <div class="-mt-4">
          <easiTextInput
            placeholder="Employee NIN"
            type="text"
            name="nin"
            class="mb-8"
            v-model="args.nin"
          ></easiTextInput>
        </div>
        <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Register
        </button>
      </div>
    </form>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>

  <main v-else class="pt-8">
    <h1 class="text-dark-800 text-xl text-center font-bold">Get an Employer Code</h1>
    <form autocomplete="on" @submit.prevent="getEmployerCode" class="flex flex-col text-left">
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <div class="w-full flex flex-col gap-4 my-5">
          <div class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between">
            <div class="flex items-center gap-2">
              <div class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0">
                <img src="@/assets/icons/pdf-icon.svg" alt="" />
              </div>
              <div class="flex flex-col leading-4">
                <div
                  v-if="uploadCacFile && uploadCacFile.name"
                  class="text-center w-9/12 font-bold text-sm flex-col leading-4"
                >
                  {{ uploadCacFile.name }}
                </div>

                <div v-else>
                  <span class="mb-0 text-xs font-bold">Upload CAC Document </span>

                  <div class="mt-0">
                    <span class="text-xs leading-none text-dark-200">Jpeg and PDF only (10MB Max)</span>
                  </div>
                </div>
              </div>
            </div>

            <span class="bg-white rounded-full">
              <UploadComponent :label="'Upload'" :showBorder="false" :loop="true" @fileUrl="uploadCacFile = $event" />
            </span>
          </div>
          <!-- <div class="justify-center mb-8">
              <UploadComponent
                @fileUrl="uploadFile = $event"
                :pill="true"
                :tiny="true"
                :label="'Choose File'"
                :info="'Upload CAC Document (Jpeg and Pdf Only) (10mb max)'"
              />
            </div> -->

          <div class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between">
            <div class="flex items-center gap-2">
              <div class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0">
                <img src="@/assets/icons/pdf-icon.svg" alt="" />
              </div>
              <div class="flex flex-col leading-4">
                <div
                  v-if="uploadMemartFile && uploadMemartFile.name"
                  class="text-center w-9/12 font-bold text-sm flex-col leading-4"
                >
                  {{ uploadMemartFile.name }}
                </div>

                <div v-else>
                  <span class="mb-0 text-xs font-bold">Upload MEMART </span>

                  <div class="mt-0">
                    <span class="text-xs leading-none text-dark-200">Jpeg and PDF only (10MB Max)</span>
                  </div>
                </div>
              </div>
            </div>

            <span class="bg-white rounded-full">
              <UploadComponent
                :label="'Upload'"
                :showBorder="false"
                :loop="true"
                @fileUrl="uploadMemartFile = $event"
              />
            </span>
          </div>
          <!-- <div class="justify-center mb-8">
              <UploadComponent
                @fileUrl="uploadFile = $event"
                :pill="true"
                :tiny="true"
                :label="'Choose File'"
                :info="'Upload MEMART (Jpeg and Pdf Only) (10mb max)'"
              />
            </div> -->

          <!-- <label for="tin" class="text-dark-800 text-sm font-medium"
              >TIN
            </label> -->
          <hr class="border-0.5 border-dividerColor text-dividerColor" />

          <div class="-mt-4">
            <easiTextInput
              placeholder="TIN"
              type="text"
              name="tin"
              class="mb-8"
              v-model="args.tin"
              required
            ></easiTextInput>
          </div>
        </div>
        <hr class="mb-3 border-0.5 border-dividerColor text-dividerColor" />
      </div>
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <easiButton
          :loading="employerLoader"
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </easiButton>
      </div>
    </form>
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj, processDate, uploadFileToServer } = helperFunctions;

const props = defineProps({
  goBack: {
    type: Boolean,
    default: false,
  },
});
const watchProp = computed(() => props.goBack);

watch(watchProp, (val) => {
  registerForCode.value = val;
});
const route = useRoute();
const emit = defineEmits(["registered", "back", "close"]);
const loading = ref(false);
const employerLoader = ref(false);

const employer = ref(true);
const employeeIds = ref([]);
const industryData = IndustryList.map((el) => el.name);
const empName = ref("");
const uploadFile = ref(null);
const uploadMemartFile = ref(null);
const uploadCacFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.state);
const successMessage = ref("");
const updateSuccess = ref(false);
const tempNin = ref("");
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const registerForCode = ref(false);
const args = ref({
  pensionCode: "",
  employeeId: "",
  passportUrl: "",
  tin: "",
  nin: "",
  cacDocument: "",
});
const allEmployees = computed(() => (getAllEmployees.value ? getAllEmployees.value.data : []));
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

function queryEmployees() {
  query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();

const assignId = (e) => {
  employeeIds.value = e;
  args.value.employeeId = e[0];
  console.log(args.value.employeeId);
  const employee = allEmployees.value.length && allEmployees.value.filter((emp) => emp._id === e[0]);
  console.log(employee);

  args.value.passportUrl =
    Object.keys(employee[0]).length && employee[0].profile && employee[0].profile.pfp ? employee[0].profile.pfp : "";
  if (args.value.passportUrl && args.value.passportUrl.length) {
    uploadFile.value = null;
  }
  empName.value =
    Object.keys(employee[0]).length && employee[0].profile
      ? `${employee[0].profile.firstName} ${employee[0].profile.lastName}`
      : "";
  args.value.nin =
    Object.keys(employee[0]).length && employee[0].profile && employee[0].profile.nin ? employee[0].profile.nin : "";
};

const createEmpList = () => {
  let list = [];
  list =
    allEmployees.value.length &&
    allEmployees.value.map((emp) => {
      return {
        label: `${emp.profile.firstName} ${emp.profile.lastName}`,
        value: emp._id,
      };
    });
  return list;
};
const countryData = computed(() => {
  let countryArray = [];
  countryArray = Countries.map((country) => {
    return country.name;
  });
  return countryArray;
});
watch(
  selectedState,
  (value, newValue) => {
    console.log(value, "New Value");
    const lgs = lgData.find((lg) => lg.state.toLowerCase() === value.toLowerCase());
    localGovtData.value =
      lgs &&
      lgs.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  },
  { deep: true }
);
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  console.log(stateArray);
  return stateArray;
});
const today = ref("");
today.value = processDate();

function closeFN() {
  emit("registered");
}
async function registerPension() {
  loading.value = true;

  let payload = {};

  if (uploadFile.value) {
    args.value.passportUrl = await uploadFileToServer(uploadFile.value);
  }
  // args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);
  const keys = Object.keys(args.value);

  // for (let k of keys) {
  //   if (args.value[k].length) {
  //     payload[k] = args.value[k];
  //   }
  // }
  //COMMENTED THIS OUT TO CATER FOR JUST EMPLOYER REGISTRATION
  // payload["pensionCode"] = args.value.pensionCode;
  // payload["cacDocument"] = args.value.passportUrl;
  // payload["tin"] = args.value.tin;

  const payloadArr = [
    {
      employeeId: args.value.employeeId,
      profile: {
        nin: args.value.nin,
        passportUrl: args.value.passportUrl,
      },
    },
  ];

  try {
    let res = await mutate({
      endpoint: "RegisterPensionEmployee",
      data: { input: payloadArr },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

async function getEmployerCode() {
  employerLoader.value = true;
  try {
    let memart, cacDocument;
    if (uploadMemartFile.value && uploadCacFile.value) {
      memart = await uploadFileToServer(uploadMemartFile.value);
      cacDocument = await uploadFileToServer(uploadCacFile.value);
    } else {
      toast.error("Upload required documents");
      return;
    }
    const res = await mutate({
      endpoint: "RegisterEmployerPaye",
      data: {
        input: {
          tin: args.value.tin,
          memart,
          cacDocument,
        },
      },
      service: "PAYROLL",
    });
    if (res.success) {
      registerForCode.value = false;
    }
    toast.success(res.message);
  } catch (e) {
    console.log(e);
  } finally {
    employerLoader.value = false;
  }
}

// function updateLg(event) {
//   console.log(event);
//   args.value.state = event;
// }
</script>

<style>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
