<template>
  <main class="md:p-4 md:px-10">
    <!-- <h4
      class="hidden md:mt-3 md:block hidden md:text-sm md:text-left text-xs text-headerText"
    >
      Compliance/ Pay Compliance
    </h4> -->
    <!-- <div class="md:block hidden text-left my-6">
      <easiBackButton></easiBackButton>
    </div> -->
    <!-- <div
      class="text-left mt-6 md:mb-3 mb-8 flex items-center justify-between md:mx-0"
    >
      <div
        class="hidden md:block text-right md:text-left md:mt-9 font-bold text-sm flex gap-x-9"
      >
        <p class="text-dark-800 text-2xl">Pay Compliance</p>
      </div>
      <easiBackButton class="md:hidden"></easiBackButton>
      <h4
        class="md:mt-3 md:hidden block md:text-sm md:text-left text-xs text-headerText"
      >
        Compliance/ Pay Compliance
      </h4>
    </div> -->
    <!-- <div class="flex w-full items-center justify-center">
      <div
        @click="payrollDetails = true"
        :class="
          payrollDetails
            ? 'bg-primary bg-secondary text-white '
            : 'border border-gray'
        "
        class="cursor-pointer rounded-full w-6 h-6 text-center flex items-center font-bold justify-center text-xs"
      >
        1
      </div>
      <hr class="w-24 my-1 border-2 text-dividerColor" />

      <div
        @click="toggleToSalaryScreen"
        :class="
          !payrollDetails
            ? 'bg-primary bg-secondary text-white '
            : 'border border-gray'
        "
        class="cursor-pointer rounded-full w-6 h-6 text-center flex items-center font-bold justify-center text-xs"
      >
        2
      </div>
    </div> -->
    <div
      v-show="payrollDetails"
      class="md:w-full w-full py-10 bg-white rounded-2xl relative text-left border border-boxBorder shadow-lg"
    >
      <div class="mb-8 text-center mx-5 md:mx-0">
        <h2 class="text-center text-headerText font-medium text-lg md:text-2xl">
          Please select compliance you want to pay
        </h2>
        <!-- <span class="text-xs text-error text-center"
          >Please fill mandatory fields (*)</span
        > -->
        <hr
          class="my-4 border-0.5 border-dividerColor hidden md:block text-dividerColor"
        />
      </div>

      <div
        class="md:grid grid-cols-3 flex flex-col md:mx-7 gap-6 mt-7 mb-7 justify-center items-center md:h-96 overflow-y-scroll"
      >
        <div
          v-for="item in complianceList"
          :key="item"
          :class="selectedComp.includes(item) ? 'bg-mint' : 'bg-highlight '"
          class="flex flex-col gap-3 rounded-xl px-4 py-3 text-left border border-primary text-xs w-11/12 md:w-auto"
        >
          <div
            @click="update(item)"
            class="flex flex-none items-center flex-nowrap justify-start mt-2 gap-x-3"
          >
            <span
              v-if="!selectedComp.includes(item)"
              class="w-4 h-4 inline-flex border border-primary flex-none items-center justify-center rounded-full"
            >
            </span>
            <img
              v-else
              class="self-end justify-self-start place-self-start"
              src="../../../assets/icons/checked.svg"
              alt=""
            />

            <span
              :class="
                selectedComp.includes(item) ? 'text-primary ' : 'text-dark-800'
              "
              class="grow cursor-pointer text-xs uppercase font-bold"
            >
              {{ item.replace(/([A-Z])/g, " $1") }}</span
            >
          </div>

          <easiSelectInput
            required
            class="mt-1 mb-1 flex-none"
            @update="updateMonthOrYear($event, item, 'year')"
            :value="complianceDates[item]['year']"
            :options="years"
            :searchable="true"
            :outline="true"
          />

          <easiMultiSelectInput
            required
            class="mt-0 mb-1 flex-none"
            @update="updateMonthOrYear($event, item, 'month')"
            :value="complianceDates[item]['month']"
            :options="monthOpt"
            :searchable="true"
            :outline="true"
          />
          <!-- 
          <easiTextInput
            placeholder="Amount"
            type="text"
            name="amount"
            class="mt-1 mb-1"
            readonly
            v-model="gratuityAmount"
            :emp="true"
            required
          ></easiTextInput> -->
        </div>
      </div>
      <div
        @click="queryPayroll"
        class="w-full flex justify-center mt-2 md:mx-auto"
      >
        <span class="w-11/12 md:w-52">
          <easiButton block class="py-4">Preview</easiButton>
        </span>
      </div>
    </div>

    <div
      v-show="!payrollDetails"
      class="md:w-full w-full py-10 bg-white rounded-2xl relative text-left"
    >
      <div
        class="bg-mint flex flex-col gap-4 items-center justify-center mb-8 text-center h-auto px-2 py-8 rounded rounded-3xl border-2 mx-auto border-primary"
      >
        <span class="font-medium text-sm text-grey"
          >Total Amount to be Paid</span
        >
        <span class="text-lg md:text-xl lg:text-3xl text-grey font-bold">{{
          formatAmount(Number(totalAmount))
        }}</span>
      </div>

      <div class="flex flex-col items-center justify-center">
        <span class="text-dark-800 text-xs font-bold">Wallet Balance</span>
        <span class="text-2xl text-secondary font-bold">{{
          balance.balance ? formatAmount(balance.balance) : formatAmount(0)
        }}</span>
        <span
          v-show="!creditBalance"
          class="text-xs text-secondary font-medium my-3"
          >Insufficient balance, please top up and proceed.</span
        >

        <div
          class="invisible flex items-center justify-center gap-x-3 mt-4 mb-2"
        >
          <input :checked="true" @change="true" type="checkbox" name="" id="" />
          <span class="text-gray text-xs font-medium">Send Payslip(s)</span>
        </div>
        <div class="self-center w-11/12 md:w-11/12">
          <easiButton
            v-if="creditBalance"
            :loading="otpLoading"
            @click="sendOTP"
            :block="true"
            :disabled="totalAmount <= 0"
            >Pay From Wallet</easiButton
          >
          <!-- v-show="Number(totalPayrollAmount.statutoryTotals) < Number(balance.balance)" -->

          <easiButton
            :block="true"
            @click="topupOption = true"
            :disabled="totalAmount <= 0"
            v-else
            >Top Up</easiButton
          >
        </div>
      </div>
    </div>

    <easiModal :payroll="true" v-if="topupOption" @close="topupOption = false">
      <template v-slot:header>
        <h1 class="capitalize font-medium text-headerText">Topup Wallet</h1>
      </template>
      <div class="px-5">
        <topUp
          @done="queryWalletBalance"
          :adminEmail="
            companyData &&
            companyData.companyAdmin &&
            companyData.companyAdmin.email
          "
        />
      </div>
    </easiModal>

    <easiModal v-if="transferDetails" @close="transferDetails = false">
      <template v-slot:header>
        <h1 class="capitalize font-medium text-headerText">
          pay via bank transfer
        </h1>
      </template>

      <div class="flex flex-col items-start px-10 gap-5">
        <div class="flex flex-col items-start">
          <span class="text-gray text-sm">Bank Name:</span>
          <span class="text-dark-800 font-medium text-base">{{
            companyAccount.bankName
          }}</span>
        </div>

        <div class="flex flex-col items-start">
          <span class="text-gray text-sm">Account Name:</span>
          <span class="text-dark-800 font-medium text-base">{{
            companyAccount.accountName
          }}</span>
        </div>
        <div class="flex gap-x-6">
          <div class="flex flex-col items-start">
            <span class="text-gray text-sm">Account Number:</span>

            <span id="copy" class="text-dark-800 font-medium text-base">{{
              companyAccount.accountNumber
            }}</span>
          </div>
          <div
            @click="copyText(companyAccount.accountNumber)"
            class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="../../../assets/icons/copy.svg" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
        </div>
      </div>
      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div
        class="flex flex-col flex-col-reverse px-6 md:px-0 md:flex-row gap-6 w-full my-6 md:justify-center md:items-center"
      >
        <span
          @click="transferDetails = false"
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-lg text-dark-800 font-medium border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          @click="
            () => {
              transferDetails = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          I Have Made The Transfer
        </easiButton>
      </div>
    </easiModal>

    <easiModal
      :isBorder="false"
      v-if="transferNotification"
      @close="transferNotification = false"
    >
      <div class="flex flex-col items-center justify-center px-10 gap-8">
        <span class="text-xl text-center mt-9 font-medium text-dark-800"
          >Wallet will be updated as <br />
          soon as the money is <br />
          received.</span
        >
        <img src="../../../assets/icons/wallet-icon.svg" alt="" />
      </div>

      <hr class="mt-8 mb-4 border-0.5 border-dividerColor text-dividerColor" />
      <div class="flex gap-x-6 w-full my-6 justify-center items-center">
        <easiButton
          @click="transferNotification = false"
          class="w-80 md:w-40 focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          <span class="text-sm"> Back to Run Payroll </span>
        </easiButton>
      </div>
    </easiModal>

    <easiModal v-if="enterOtp" @close="enterOtp = false" :isBorder="false">
      <div class="w-full px-2 md:px-8 pb-8 text-center">
        <div class="mb-4">
          <h2 class="text-primary font-bold text-2xl md:text-2xl">
            OTP Verification
          </h2>
          <span class="text-sm md:text-base mt-1 font-medium block">
            Please input OTP sent to your email and phone number
          </span>
        </div>
        <form class="w-full" @submit.prevent="confirmOtp">
          <span class="mb-4 flex justify-center items-center">
            <easiOtp
              size="10"
              :token_length="6"
              :max-length="1"
              v-model="otpCode"
              @input="checkOTP"
              :error="errorRules.otpCode"
            />
          </span>
          <div
            class="text-secondary justify-center text-sm flex items-center gap-1"
          >
            <div
              class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background"
            >
              {{ minutes }}
            </div>
            :
            <div
              class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background"
            >
              {{ seconds }}
            </div>
          </div>
          <div
            class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
          >
            Did not receive OTP?
            <easiButton
              type="button"
              @click="sendOTP('EMAIL')"
              variant="text"
              color="secondary"
              class="text-base px-0"
              :loading="resendOtpLoading"
              :class="disabledBtn ? 'cursor-not-allowed' : undefined"
              :disabled="disabledBtn"
              >Resend</easiButton
            >
          </div>

          <easiTextInput
            v-if="fullCompanyData && fullCompanyData.twoFactorAuth"
            v-model="securityCode"
            class="mx-3 mt-8 mb-10"
            :placeholder="codeLabel"
            type="text"
            :error="twoFaError"
            required
          />
          <div
            class="text-dark-800 mb-4 text-xs font-medium text-center flex items-center justify-center"
          >
            <easiButton
              type="button"
              @click="sendOTP('SMS')"
              variant="text"
              color="secondary"
              class="text-xs font-bold px-0"
              :class="disabledBtn ? 'cursor-not-allowed' : undefined"
              :disabled="disabledBtn"
              :loading="resendOtpLoading"
              >Send to phone number instead</easiButton
            >
          </div>
          <easiButton
            block
            class="mt-5"
            color="primary"
            :loading="confirmOtpLoading"
            :disabled="otpCode && otpCode.length != 6"
          >
            {{ otpAction }}
          </easiButton>
        </form>
      </div>
    </easiModal>

    <easiModal v-if="schedulePayment" @close="schedulePayment = false">
      <template v-slot:header>
        <div class="flex items-center mb-3 gap-5">
          <img class="w-12 md:w-auto" src="@/assets/img/calendar.png" alt="" />
          <h1 class="font-bold md:text-2xl text-xl">Schedule Payment</h1>
        </div>
      </template>

      <div class="flex flex-col gap-4 items-center mt-6">
        <h2 class="font-bold text-sm text-dark-800">
          Please Select Payment Date
        </h2>
      </div>
      <hr class="mt-6 mb-5 border-0.5 border-dividerColor text-dividerColor" />

      <form
        class="flex flex-col items-start justify-center text-left px-2 md:px-9"
        action=""
      >
        <label
          for="startDate"
          class="text-dark-600 text-sm text-left font-medium"
          >Select Date</label
        >
        <input
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
          type="date"
          name="startDate"
          id="startDate"
          :min="today"
        />
        <div class="w-full">
          <easiButton
            @click="schedulePayment = false"
            :block="true"
            class="w-full"
            >Schedule</easiButton
          >
        </div>
      </form>
    </easiModal>

    <easiModal v-if="payStack" @close="payStack = false">
      <template v-slot:header>
        <div class="flex flex-col items-center justify-center6 gap-2">
          <h1 class="capitalize font-medium text-headerText">pay via</h1>

          <div class="bg-white w-11/12 rounded rounded-xl p-5">
            <img src="../../../assets/icons/paystack.svg" alt="" />
          </div>
        </div>
      </template>

      <div v-show="!linkGenerated" class="flex flex-col items-center">
        <form class="flex gap-4" action="">
          <div>
            <label class="text-dark-800 font-normal text-sm mr-2" for="amount"
              >One Time Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="false"
              v-model="recurrentPayment"
              :checked="!recurrentPayment"
            />
          </div>

          <div>
            <label
              class="text-dark-800 font-normal text-sm mx-2"
              for="percentage"
              >Recurrent Payment</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="true"
              v-model="recurrentPayment"
              :checked="recurrentPayment"
            />
          </div>
        </form>
      </div>

      <span
        v-show="linkGenerated"
        @click="copyText(link)"
        class="text-hoverColor font-medium text-sm text-center"
        >{{ link }}</span
      >

      <div
        v-show="linkGenerated"
        class="flex mt-2 gap-x-5 justify-center items-center"
      >
        <div
          @click="copyText(link)"
          class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
        >
          <img src="../../../assets/icons/copy.svg" alt="" />
          <span class="text-dark-800 font-bold inline-block text-sm">Copy</span>
        </div>
        <div
          @click="shareLink = true"
          class="bg-white rounded rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
        >
          <img src="../../../assets/icons/share.svg" alt="" />
          <span class="text-dark-800 font-bold inline-block text-sm"
            >Share</span
          >
        </div>
      </div>

      <hr class="mt-5 mb-7 border-0.5 border-dividerColor text-dividerColor" />
      <div
        v-show="linkGenerated"
        class="flex flex-col md:flex-row px-6 md:px-0 gap-6 w-full my-6 md:justify-center flex-col-reverse md:items-center"
      >
        <span
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
            }
          "
          class="w-full md:w-40 cursor-pointer text-center bg-white rounded-lg text-dark-800 font-medium border border-secondary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          @click="
            () => {
              payStack = false;
              linkGenerated = false;
              transferNotification = true;
            }
          "
          class="w-full md:w-auto focus:outline-none text-sm py-3 px-4 bg-secondary rounded-lg text-white"
        >
          <span class="text-sm"> I Have Made The Transfer</span>
        </easiButton>
      </div>

      <form
        @submit.prevent="initPaystack"
        v-show="!linkGenerated"
        class="flex flex-col w-full"
        autocomplete="on"
      >
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <label
            for="paymentName"
            class="text-left text-dark-800 text-sm font-medium"
            >Payment Name</label
          >
          <easiTextInput
            placeholder="Payment Name"
            type="text"
            name="paymentName"
            class="mt-1 mb-5"
          />

          <!-- <label
            for="description"
            class="text-dark-800 text-left text-sm font-medium"
            >Description
          </label> -->
          <easiTextArea
            class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
            name="description"
            id="description"
            cols="30"
            rows="5"
            placeholder="Payment Description"
          ></easiTextArea>

          <label
            for="amount"
            class="text-left text-dark-800 text-sm font-medium"
            >Amount</label
          >
          <easiTextInput
            placeholder="Input Amount"
            type="text"
            :format="true"
            name="amount"
            v-model="paystackArgs.amount"
            class="mt-1 mb-5"
            required
          />
          <label
            v-show="recurrentPayment"
            for="Interval"
            class="text-dark-800 text-left text-sm font-medium"
            >Select Interval</label
          >

          <easiSelectInput
            v-if="recurrentPayment === true"
            required
            class="mt-1 mb-5 text-left"
            @update="interval = $event"
            :value="interval"
            :options="payrollInterval"
            :searchable="true"
            :autoHeight="true"
          />
        </div>
        <div
          class="md:w-11/12 w-full my-3 md:justify-center md:items-center px-4 md:px-0"
        >
          <easiButton
            :block="true"
            class="md:w-auto focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Generate Payment Link
          </easiButton>
        </div>
      </form>
    </easiModal>

    <easiModal v-if="shareLink" @close="shareLink = false">
      <template v-slot:header>
        <div class="flex items-center gap-x-4 justify-center">
          <img
            class="w-5 md:w-auto"
            src="@/assets/icons/share-black.svg"
            alt=""
          />
          <h1
            class="capitalize text-xl md:text-3xl font-medium text-headerText"
          >
            Share
          </h1>
        </div>
      </template>

      <form @submit.prevent="sendEmail" class="flex flex-col" autocomplete="on">
        <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
          <label for="email" class="text-left text-dark-800 text-sm font-medium"
            >Email Address</label
          >
          <easiTextInput
            v-model="emailAddress"
            placeholder="Input Email Address"
            type="email"
            name="email"
            class="mt-1 mb-5"
            required
          />
        </div>
        <hr
          class="mt-3 md:mb-8 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="md:px-10 px-2 w-full">
          <easiButtonNew
            :loading="loading"
            :block="true"
            class="w-full mb-4 md:mb-5"
            >Send Mail</easiButtonNew
          >
        </div>
      </form>
    </easiModal>

    <easiPreview v-if="previewModal" @close="previewModal = false">
      <template v-slot:header>
        <h1>PREVIEW</h1>
      </template>

      <template v-slot:body>
        <div v-if="tableData.length" class="w-full">
          <easiTable
            :data="tableData"
            :header="configureHeaders"
            :highlight="['compliance', 'month']"
            :mandatory="['firstName', 'lastName', 'Phone Number', 'Email']"
            :readonly="true"
          ></easiTable>
        </div>

        <div
          class="flex md:flex-row flex-col items-center justify-center gap-x-7 gap-y-2 mt-10 mb-3"
        >
          <div class="hidden text-sm py-4 w-full sm:w-80 md:w-64">
            <easiButton
              block
              class="py-4"
              color="primary"
              variant="outlined"
              @click="
                () => {
                  payrollDetails = false;
                  previewModal = false;
                  otpAction = 'Schedule';
                  enterOtp = true;
                }
              "
              >Schedule Payment</easiButton
            >
          </div>

          <div class="text-sm py-4 w-full sm:w-80 md:w-64">
            <easiButton
              block
              class="py-4"
              @click="
                () => {
                  payrollDetails = false;
                  previewModal = false;
                }
              "
              >Pay Now</easiButton
            >
          </div>
        </div>
      </template>
    </easiPreview>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span class="capitalize">{{ successMessage }}</span>
      </template>

      <template v-slot:action>
        <easiButton @click="returnToPayroll"
          >Back to Compliance List</easiButton
        >
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed } from "vue";
import topUp from "@/components/Wallet/Topup.vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
const toast = useToast();

const emit = defineEmits(["completed"]);

const props = defineProps({
  employees: {
    type: Array,
    default: [],
  },
  allowances: {
    type: Array,
    default: [],
  },
  totalAmount: {
    type: Number,
    default: 0,
  },
  payloadData: {
    type: Array,
  },
});

const { formatAmount, calculatePercentage, processDate } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const bonusDeduction = computed(() => store.getBonusAndDeduction);
const previewDataForPayroll = computed(() => store.getPayrollPreview);
const totalPayrollAmount = computed(() => store.getTotalPayroll);
const companyData = computed(() => store.getCompanyDetails);
const allEmployees = computed(() =>
  store.getAllEmployees ? store.getAllEmployees.data : []
);
const amountToBePaid = ref(0);
const twoFaError = ref("");
const securityCode = ref("");
const securityQuestions = computed(
  () => store.getActiveSecurityQuestion && store.getActiveSecurityQuestion
);

const fullCompanyData = computed(() =>
  store.getCompanyAdmin ? store.getCompanyAdmin : {}
);
onMounted(() => {
  totalPayrollAmount.value &&
  typeof totalPayrollAmount.value.statutoryTotals === "number"
    ? Number(totalPayrollAmount.value.statutoryTotals)
    : 0;
});
const minutes = ref(2);
const seconds = ref("00");
const timer = ref(120);
const disabledBtn = ref(false);
function startTimer() {
  disabledBtn.value = true;
  const interval = setInterval(function () {
    minutes.value = parseInt(timer.value / 60, 10);
    seconds.value = parseInt(timer.value % 60, 10);

    minutes.value = minutes.value < 10 ? "0" + minutes.value : minutes.value;
    seconds.value = seconds.value < 10 ? "0" + seconds.value : seconds.value;

    if (--timer.value < 0) {
      timer.value = 120;
      disabledBtn.value = false;
      clearInterval(interval);
    }
  }, 1000);
}
const queryActiveSecurityQuestion = async () => {
  try {
    await query({
      endpoint: "ListSecurityQuestions",
      service: "SETTINGS",
      storeKey: "userSecurityQuestions",
    });
    // console.log(securityQuestions.value, "SQUEST");
  } catch (e) {
    console.log(e.message);
  }
};
queryActiveSecurityQuestion();

const codeLabel = computed(() => {
  if (fullCompanyData.value && fullCompanyData.value.twoFactorAuth) {
    const opts = {
      // SecurityQuestions:
      //   securityQuestions.value && securityQuestions.value.length
      //     ? securityQuestions.value[0].question.question
      //     : "",
      securityquestions: "Enter the answer to your secret question",

      sms: "Enter 2FA OTP",
      authenticator: "Open your authenticator app and enter code",
      backupcode: "Enter backup code",
    };
    // console.log(opts, fullCompanyData.value.twoFactorAuth.authType);
    return (
      opts[fullCompanyData.value.twoFactorAuth.authType.toLowerCase()] || ""
    );
  }
  return "";
});

const today = ref("");
today.value = processDate();
const payloadArr = ref([]);

const payStackRes = computed(() => store.getPayStackResponse);
const paystackArgs = ref({
  amount: 0,
  email: "",
});

const args = ref({
  employeeIds: [],
  month: 0,
  year: 0,
  // biweekly: "FIRSTHALF",
  bonuses: [],
  deductions: [],
  timeline: "MONTHLY",
  statutories: [],
  paymentOptions: {
    salary: false,
    bonus: false,
    statutory: true,
    deduction: false,
  },
});
function matchStatutoryDeds(arr) {
  const data = arr.map((item) => {
    if (item === "paye") return "PAYE";
    if (item === "pension") return "Pension";
    if (item === "nhf") return "NHF";
    if (item === "nsitf") return "NSITF";
    if (item === "itf") return "ITF";
    if (item === "wht") return "WHT";
    if (item === "lifeInsurance") return "Group_Life_Insurance";
    if (item === "lifeAssurance") return "Employee_Life_Assurance";
    if (item === "hmo") return "HMO";
    if (item === "businessExpense") return "Business_Expense";
    if (item === "gratuity") return "Gratuity";
  });
  return data;
}

const companyAccount = computed(() => store.getCompanyAccountDetails);
const balance = computed(() => store.getWalletBalance);

const creditBalance = computed(() => {
  const amount =
    props.totalAmount && typeof props.totalAmount === "number"
      ? props.totalAmount
      : 0;

  return Number(balance.value ? balance.value.balance : 0) >= amount;
});
async function queryAllowance() {
  await query({
    endpoint: "ListAllowance",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: null,
      },
    },
    service: "PAYROLL",
    storeKey: "allowance",
  });
}
const selectedComp = ref([]);
const otpAction = ref("Pay Compliance");
const complianceList = ref([
  "paye",
  "pension",
  "hmo",
  // "lifeInsurance",
  "lifeAssurance",
  "nsitf",
  "itf",
  "nhf",
  "wht",
  // "gratuity",
  // "businessExpense",
]);
const createObj = () => {
  const data = complianceList.value.reduce(function (r, a) {
    r[a] = r[a] || {};
    r[a]["employeeIds"] = allEmployees.value.map((emp) => emp._id);
    r[a]["statutories"] = matchStatutoryDeds([a]);
    r[a]["bonuses"] = [];
    r[a]["deductions"] = [];
    r[a]["timeline"] = "MONTHLY";
    r[a]["paymentOptions"] = {
      salary: false,
      bonus: false,
      statutory: true,
      deduction: false,
    };

    r[a]["month"] = [];
    r[a]["year"] = "";
    return r;
  }, {});

  return data;
};
const complianceDates = ref(createObj());
async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
        sort: { firstName: "ASC" },
      },
      filter: [],
    },
    service: "EMP",
    storeKey: "listEmployees",
  });
}

queryEmployees();
queryAllowance();

const successMessage = ref("");
const updateSuccess = ref(false);
const bonusData = ref([]);
const deductionData = ref([]);
const bonusOptions = ref([]);
const deductionOptions = ref([]);
const bonuses = ref([]);
const deductions = ref([]);
const insufficientBal = ref(true);
const bankTransfer = ref(true);
const topupOption = ref(false);
const headerKey = ref([]);
const transferDetails = ref(false);
const transferNotification = ref(false);
const payStack = ref(false);
const linkGenerated = ref(false);
const shareLink = ref(false);
const loading = ref(false);
const otpLoading = ref(false);
const resendOtpLoading = ref(false);
const confirmOtpLoading = ref(false);

const enterOtp = ref(false);
const otpCode = ref("");

const payrollMonth = ref("");
const otpReference = ref("");
const configureHeaders = computed(() => {
  let headArr = [];
  const head = Object.keys(tableData.value[0]);
  //   const head = headerKey.value;
  for (let i of head) {
    headArr.push({
      field: i,
      header: i,
    });
  }
  return headArr;
});

const link = ref(
  "9DIJxLyx3H85tP87hYF8eg/EAZIPAY-NEW-WEB-APP-PROJECT?node-id=6150%3EAZIPAY-NEW-WEB-Flutterwaveaccount7."
);

const previewModal = ref(false);
const payrollDetails = ref(false);
const schedulePayment = ref(false);
const years = ref([]);
const paymentOption = ref([]);
const interval = ref("");
const tableData = ref([
  {
    sn: 1,
    firstName: "Aanu",
    lastName: "Abayomi",
    compliance: "PAYE",
    month: "November",
    year: "2022",
    paymentPartner: "Ikeja Tax Office",
  },
]);
const bonusNames = ref([]);
const deductionNames = ref([]);
const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phoneNumber: false,
  companyName: false,
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
});

const toggleToSalaryScreen = () => {
  payrollDetails.value = !payrollDetails.value;
};

const recurrentPayment = ref(false);
const accountNumber = ref("0711456765");

const paymentOptions = ref([
  {
    label: "Net Salary",
    value: "netSalary",
  },
  {
    label: "Bonus",
    value: "bonus",
  },
  {
    label: "Statutory Deductions",
    value: "statutoryDeductions",
  },
  {
    label: "Deductions",
    value: "deduction",
  },
]);

function copyText(val) {
  var text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}
const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
const monthOpt = computed(() => {
  const data = months.value.map((month, index) => {
    return {
      label: month,
      value: String(index),
    };
  });
  return data;
});
function getIndexByMonth(month) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // return months[index];
  return months.indexOf(month);
}

function getMonthByIndex(index) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[index];
}
const payrollInterval = ref(["Weekly", "Monthly"]);
const statutoryOptions = ref([
  {
    label: "PAYE",
    value: "PAYE",
  },
  {
    label: "Pension",
    value: "Pension",
  },
  {
    label: "NHF",
    value: "NHF",
  },
  {
    label: "NSITF",
    value: "NSITF",
  },
  {
    label: "ITF",
    value: "ITF",
  },
  {
    label: "WHT",
    value: "WHT",
  },
  {
    label: "HMO",
    value: "HMO",
  },
  {
    label: "Life Insurance",
    value: "Group_Life_Insurance",
  },
  {
    label: "Life Assurance",
    value: "Employee_Life_Assurance",
  },
  {
    label: "Business Expense",
    value: "Business_Expense",
  },
  {
    label: "Gratuity",
    value: "Gratuity",
  },
]);

const currentYear = new Date().getFullYear();
for (let i = currentYear; i >= 1990; i--) {
  years.value.push(String(i));
}
const mapIdToName = () => {
  if (bonuses.value.length) {
    const bon = bonusData.value.map((bo) => {
      // console.log(bo);
      if (bonuses.value.includes(bo._id)) {
        return bo._id;
      }
    });
    args.value.bonuses = bon.filter((item) => item != null);
  }

  if (deductions.value.length) {
    const dedu = deductionData.value.map((ded) => {
      if (deductions.value.includes(ded._id)) {
        return ded._id;
      }
    });
    args.value.deductions = dedu.filter((item) => item != null);
  }
};
const capitalize = (str) => {
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
};

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}
async function update(param) {
  if (param) {
    if (selectedComp.value.includes(param)) {
      // const newArr = [...selectedComp.value.filter((opt) => param !== opt)];
      let index = selectedComp.value.indexOf(param);
      if (index > -1) {
        selectedComp.value.splice(index, 1);
      }
    } else {
      selectedComp.value.push(param);
      //   nextTick();
    }

    // emit("update", selectedComp.value);
  }
}

function updateMonthOrYear(e, param, time) {
  complianceDates.value[param][time] = e;
  if (!selectedComp.value.includes(param)) {
    update(param);
  }
}

async function sendOTP(via) {
  let admin;
  if (via && typeof via === "string" && via === "SMS") {
    admin =
      fullCompanyData.value &&
      fullCompanyData.value.companyAdmin &&
      fullCompanyData.value.companyAdmin.email; //changed from phoneNumber to email but keeping the code in case it's later changed to phone number
  } else {
    admin =
      fullCompanyData.value &&
      fullCompanyData.value.companyAdmin &&
      fullCompanyData.value.companyAdmin.email;
  }
  //validate form field
  const payload = {
    channel: via && typeof via === "string" ? via : "EMAIL",

    value: admin,

    type: "RUN_PAYROLL",
  };

  otpLoading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      startTimer();
      enterOtp.value = true;
      otpLoading.value = false;
      otpReference.value = res.data.reference;
      // toast.success("OTP")
      // window.localStorage.setItem("otpReference", res.data.reference);
    }
  } catch (e) {
    otpLoading.value = false;
  }
}

async function confirmOtp() {
  const admin = fullCompanyData.value.companyAdmin.email;

  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    confirmOtpLoading.value = true;

    const payload = {
      reference: otpReference.value,
      code: otpCode.value,
      value: admin,
    };

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: { input: payload },
        service: "AUTH",
      });

      if (res.success) {
        //Clear error state
        errorRules.otpCode = false;

        //Register user
        await processPayroll();
        confirmOtpLoading.value = false;
        enterOtp.value = false;
      }
    } catch (e) {
      confirmOtpLoading.value = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    }
  }
}

// async function resendOTP() {
//   const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));

//   // log(args);
//   // log("user details", userDetails);

//   const payload = {
//     channel: "EMAIL",
//     token_length: 4,
//     email: userDetails.email,
//     type: "SIGNUP",
//     phoneNumber: processNumber(userDetails.phoneNumber),
//     firstName: userDetails.firstName,
//   };

//   try {
//     let res = await mutate({
//       endpoint: "SendOTP",
//       data: { input: payload },
//       service: "AUTH",
//     });

//     if (res.success) {
//       loading.btnOtp = false;
//       toast.success("OTP sent successful. Please check email");
//       window.localStorage.setItem("otpReference", res.data.reference);
//     }
//   } catch (e) {
//     loading.btnOtp = false;
//   }
// }

const convertResData = (data) => {
  const newData = data.map((d) => {
    return {
      // id: d.profile.employeeId,
      firstName: d.profile.firstName,
      lastName: d.profile.lastName,
      compliance: selectedComp.value
        .map((comp) => comp.replace(/([A-Z])/g, " $1").toUpperCase())
        .join(","),
      year: Array.from(new Set(payloadArr.value.map((item) => item.year))).join(
        ","
      ),
      month: Array.from(
        new Set(payloadArr.value.map((item) => getMonthByIndex(item.month)))
      ).join(","),
    };
  });
  // console.log(newData, "NEDATA");

  return newData;
};
const balanceInWallet = ref(0);
onMounted(() => {
  balanceInWallet.value = balance.value.balance;
});

const queryWalletBalance = async () => {
  // loading.value = true;
  try {
    const id = "";
    let res = await query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: id,
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });

    // loading.value = false;
    return res.balance;
  } catch (err) {
    // loading.value = false;
    console.log(err);
  } finally {
    // loading.value = false;
  }
};
const startQueryingBalance = async () => {
  try {
    const initialBalance = balanceInWallet.value;

    while (true) {
      const newBalance = await queryWalletBalance();

      if (newBalance !== initialBalance) {
        balanceInWallet.value = newBalance;
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay between queries
    }
  } catch (err) {
    console.error(err);
  }
};
const queryCalculatePayments = async (arg) => {
  loading.value = true;

  try {
    let res = await query({
      endpoint: "CalculateMultiplePayment",
      payload: {
        input: arg,
      },
      service: "PAYROLL",
      storeKey: "totalPayroll",
    });
    // console.log(res);
    amountToBePaid.value =
      res && typeof res.statutoryTotals === "number"
        ? Number(res.statutoryTotals)
        : 0;
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
const queryPayroll = async () => {
  let selected = {};
  for (let k of Object.keys(complianceDates.value)) {
    if (selectedComp.value.includes(k)) {
      selected[k] = complianceDates.value[k];
    }
  }
  let data = selected;
  let newData = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const obj = data[key];
      if (obj.month.length > 0) {
        obj.month.forEach((month) => {
          newData.push({
            ...obj,
            month: Number(month),
            year: Number(obj.year),
          });
        });
      }
    }
  }
  payloadArr.value = newData;

  // console.log(newData);

  // for (const key in data) {
  //   const monthArray = data[key].month;
  //   const numDuplications = monthArray.length;
  //   for (let i = 0; i < numDuplications; i++) {
  //     const duplicate = JSON.parse(JSON.stringify(data[key]));
  //     duplicate.month = monthArray[i];
  //     data[`${key}_${i}`] = duplicate;
  //   }
  // }
  // console.log(data, "Worh kwy");
  // const myArray = [...Object.values(data)];
  // console.log(myArray);

  // mapIdToName(); since there are no bonus or deductions
  // args.value.month = getIndexByMonth(payrollMonth.value);
  // args.value.employeeIds = allEmployees.value.map((emp) => emp._id);
  // args.value.year = Number(args.value.year);
  // args.value.statutories = matchStatutoryDeds(selectedComp.value);

  if (payloadArr.value.length <= 0) {
    toast.error("You have to choose the year and month to pay for.");
    return false;
  }

  try {
    loading.value = true;
    await query({
      endpoint: "PreviewPayrollMultiple",
      payload: {
        input: payloadArr.value,
      },
      service: "PAYROLL",
      storeKey: "previewPayroll",
    });
    // console.log(previewDataForPayroll.value, "RESPONSE");
    // return true;
    const resData = convertResData(previewDataForPayroll.value);
    tableData.value = resData;
    await queryCalculatePayments(payloadArr.value);

    loading.value = false;
    if (tableData.value.length) {
      //   console.log(tableData.value, "FRES");
      //   log(tableData.value);
      previewModal.value = true;
    }
  } catch (err) {
    loading.value = false;
    toast.error(err.message);
    console.log(err);
  }
};

// function transform(arr) {
//   const benArr = [
//     "paye",
//     "pension",
//     "hmo",
//     "lifeInsurance",
//     "lifeAssurance",
//     "nsitf",
//     "itf",
//     "nhf",
//     "wht",
//     "gratuity",
//     "businessExpense",
//   ];
//   let keys = ["firstName", "lastName", "compliance", "month", "year"];
//   // args.value.statutories = matchStatutoryDeds(selectedComp.value);

//   // if (args.value.paymentOptions.statutory) {
//   //   for (let i of selectedComp.value) {
//   //     keys.push(i);
//   //   }
//   // }
//   // if (args.value.paymentOptions.bonus) {
//   //   for (let i of bonusNames.value) {
//   //     if (args.value.bonuses.includes(i)) {
//   //       keys.push(i);
//   //     }
//   //   }
//   // }
//   // if (args.value.paymentOptions.deduction) {
//   //   for (let i of deductionNames.value) {
//   //     if (args.value.deductions.includes(i)) {
//   //       keys.push(i);
//   //     }
//   //   }
//   // }
//   // if (args.value.paymentOptions.salary) {
//   //   keys.splice(2, 0, "netAmount");
//   //   keys.splice(2, 0, "grossAmount");
//   //   for (let i of props.allowances) {
//   //     keys.splice(4, 0, i);
//   //   }
//   // }

//   console.log("Keysssssss");
//   log(keys);
//   headerKey.value = keys.filter((el) => el != null);

//   const newArr = arr.map((emp) => {
//     const data = keys
//       .filter((key) => {
//         // for (let k of keys) {
//         //   if (!Object.keys(emp).includes(k)) {
//         //     console.log(k);
//         //     emp[k] = null;
//         //   }
//         // }

//         // return keys.includes(key);
//         emp[key] ? emp[key] : "";
//         return emp;
//       })
//       .reduce((obj, key) => {
//         if (typeof emp[key] === "object") {
//           console.log(key, "KEY");
//           return Object.assign(obj, {
//             [key]: emp[key]
//               ? emp[key].amount
//                 ? formatAmount(Number(emp[key].amount))
//                 : formatAmount(
//                     Number(
//                       calculatePercentage(emp.grossAmount, emp[key].percentage)
//                     )
//                   )
//               : "N/A",
//           });
//         }
//         if (typeof emp[key] === "number") {
//           return Object.assign(obj, {
//             [key]: emp[key] ? formatAmount(Number(emp[key])) : "N/A",
//           });
//         }
//         return Object.assign(obj, {
//           [key]: emp[key] ? emp[key] : "N/A",
//         });
//       }, {});

//     return data;
//   });

//   return newArr;
// }

const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;

    bonusOptions.value = bonusData.value.map((bd) => {
      bonusNames.value.push(bd.name);
      return {
        label: bd.name,
        value: bd._id,
      };
    });
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
    deductionOptions.value = deductionData.value.map((bd) => {
      deductionNames.value.push(bd.name);
      return {
        label: bd.name,
        value: bd._id,
      };
    });
  } catch (err) {
    console.log(err);
  }
};
const payload = ref({
  input: [],
  twoFa: {},
});
const processPayroll = async () => {
  const twoFa = fullCompanyData.value;
  payload.value["input"] = props.payloadData;
  // let payload = {
  //   input: payloadArr.value,
  //   twoFa: {},
  // };

  // const finalPayload = payload;

  try {
    loading.value = true;
    if (twoFa.twoFactorAuth !== null) {
      if (!securityCode.value.length) {
        twoFaError.value = "2FA is required";
        return;
      }
      if (twoFa.twoFactorAuth.authType.toLowerCase() === "securityquestions") {
        payload.value.twoFa["securityQuestions"] = {
          questions: [
            {
              question: twoFa.twoFactorObj.securityQuestions[0].question,
              answer: securityCode.value,
            },
          ],
        };
      }
      if (twoFa.twoFactorAuth.authType.toLowerCase() === "sms") {
        payload.value.twoFa["sms"] = {
          otpRef: securityCode.value,
        };
      }

      if (twoFa.twoFactorAuth.authType.toLowerCase() === "authenticator") {
        payload.value.twoFa["authenticator"] = {
          code: securityCode.value,
        };
      }

      if (twoFa.twoFactorAuth.authType.toLowerCase() === "backupcode") {
        payload.value.twoFa["backupCode"] = {
          code: securityCode.value,
        };
      }
      payload.value.twoFa["authType"] = twoFa.twoFactorAuth.authType;
    } else {
      delete payload.value["twoFa"];
    }
    console.log("RAN");
    let res = await mutate({
      endpoint: "ProcessPayrollMultiple",
      data: payload.value,
      service: "PAYROLL",
    });
    if (res.success) {
      //visit later for proper response handling
      successMessage.value = res.message;
      updateSuccess.value = true;
      loading.value = false;
    } else {
      toast.error(res);
      loading.value = false;
    }
    // console.log(res, "PROCESS PAYROll");
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

const returnToPayroll = () => {
  updateSuccess.value = false;
  emit("completed", false);
};

async function queryBonusOrDed() {
  try {
    await this.query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
  } catch (err) {
    console.log(err);
  }
}
const initPaystack = async () => {
  paystackArgs.value.amount = Number(paystackArgs.value.amount);
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "PaystackPaymentInit",
      data: { input: paystackArgs.value },
      service: "PAYROLL",
    });

    if (res.authorizationUrl.length) {
      link.value = res.authorizationUrl;
      await verifyPaystack(res.reference);
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
};

async function verifyPaystack(paymentReference) {
  try {
    await query({
      endpoint: "VerifyPaystackPayment",
      service: "PAYROLL",
      payload: { input: { paymentReference } },
      storeKey: "payStackResponse",
    });
    if (payStackRes.value.paymentSuccessful) {
      paystackArgs.value.amount = 0;
      bankTransfer.value = true;
      // payStack.value = false;
      linkGenerated.value = true;
      loading.value = false;
    }
  } catch (e) {
    console.log(e);
  }
}

const emailAddress = ref("");
const mailArgs = ref({
  HTMLPart: null,
  To: [
    {
      Email: "",
    },
  ],
  Subject: "",
});

async function sendEmail() {
  mailArgs.value.HTMLPart = link.value;

  mailArgs.value.Subject = `Paystack payment link`;
  mailArgs.value.To[0].Email = emailAddress.value;
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendEmail",
      data: { input: mailArgs.value },
      service: "PAYROLL",
    });
    if (res.success) {
      loading.value = false;
      shareLink.value = false;
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
}

const queryAdmin = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e.message);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  paystackArgs.value.email =
    fullCompanyData.value &&
    fullCompanyData.value.companyAdmin &&
    fullCompanyData.value.companyAdmin.email;
});

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

queryAdmin();
queryCompanyAccountDetails();
queryWalletBalance();
queryBonus();
queryDeduction();
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
