<template>
  <div
    v-if="showOptions || backBool"
    class="p-1 w-full h-11/12 text-center flex flex-col gap-y-2"
  >
    <h1 class="font-medium capitalize text-xl font-bold">PAYE Registration</h1>
    <span class="text-center text-bodyDark text-sm"
      >Kindly select your registration option</span
    >

    <div
      class="cursor-pointer border border-outlineGray p-3 w-11/12 rounded-xl flex flex-col gap-y-3 mt-5 self-center"
    >
      <div
        v-for="type in optionTypes"
        :key="type"
        @click="handleType(type)"
        class="bg-highlight border border-outlineGray flex justify-between rounded-lg p-2"
      >
        <span class="capitalize text-sm font-semibold">
          {{ type }} Registration</span
        >

        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.875 16.25L13.125 10L6.875 3.75"
            stroke="#0F172A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
  <main
    v-else
    :class="isChosen ? 'boxHeight' : ''"
    class="relative overflow-x-hidden overflow-y-auto w-full"
  >
    <div class="sticky top-0 pointer-events-none top-0 bg-white z-50">
      <h2 class="capitalize text-center font-bold text-bodyDark text-xl">
        {{ regType }} registration
      </h2>
    </div>
    <div class="w-full mt-2">
      <div
        :class="
          registeredEmployee === false ? 'border border-dividerColor my-5' : ''
        "
        class="rounded-3xl py-2 px-4 mx-8"
      >
        <div
          v-show="!employer"
          class="flex justify-center items-center gap-x-2 mb-4"
        >
          <span class="text-sm text-dark-800 font-bold">A new employee?</span>

          <div
            class="flex cursor-pointer w-24 items-center gap-x-4 rounded-lg py-1 border border-outlineGray px-1 bg-newAsh text-newGray font-bold"
          >
            <span
              @click="registeredEmployee = true"
              class="text-xs px-1 w-full"
              :class="
                registeredEmployee === true
                  ? 'bg-white  p-1  rounded-md'
                  : undefined
              "
              >YES</span
            >
            <span
              @click="registeredEmployee = false"
              class="text-xs px-1 w-full"
              :class="
                registeredEmployee === false
                  ? 'bg-white  p-1 rounded-md'
                  : undefined
              "
              >NO</span
            >
          </div>
        </div>

        <p
          v-show="registeredEmployee === false"
          for="email"
          class="text-bodyDark w-full text-center text-xs font-medium"
        >
          Add employee to auto fill details
        </p>
        <easiEmployeeBonus
          v-show="registeredEmployee === false"
          :single="true"
          :directorView="true"
          @update="updateEmp($event)"
          :value="employeeIds"
          :options="createEmpList()"
          class="mt-2 mb-1"
        />
      </div>
      <hr class="mb-5 mx-8 border-0.5 border-dividerColor text-dividerColor" />

      <form
        @submit.prevent="registerPaye"
        v-show="employer"
        autocomplete="on"
        class="flex flex-col text-left"
      >
        <div class="px-2 sm:px-4 lg:px-4 xl:px-4">
          <!-- <label for="email" class="text-dark-800 text-sm font-medium"
          >Email Address
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="Full Company Name "
            type="text"
            name="companyName"
            v-model="args.employer.companyName"
            class="mt-1 mb-10"
          />
          <easiTextInput
            required
            placeholder="Email Address"
            type="email"
            name="email"
            class="mt-1 mb-10"
            v-model="args.employer.email"
            autocom
          />
          <!-- <label for="cac" class="text-dark-800 text-sm font-medium"
          >CAC Registration Number
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="CAC Registration Number"
            type="text"
            name="cac"
            v-model="args.employer.cacNumber"
            class="mt-1 mb-10"
          />

          <!-- <label for="companyName" class="text-dark-800 text-sm font-medium"
          >Full Company Name
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->

          <!-- <label for="companyAddress" class="text-dark-800 text-sm font-medium"
          >Full Company Address
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="Full Company Address"
            type="text"
            name="companyAddress"
            v-model="args.employer.address"
            class="mt-1 mb-10"
          />

          <!-- <label for="contactMail" class="text-dark-800 text-sm font-medium"
          >Contact Email
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <!-- <easiTextInput
          required
          placeholder="Contact Email "
          type="email"
          name="contactMail"
          class="mt-1 mb-10"
          autocom
        /> -->

          <!-- <label for="phoneNumber" class="text-dark-800 text-sm font-medium"
          >Contact Phone Number
          <span class="text-xs text-error tracking-wide"> *</span>
        </label> -->
          <easiTextInput
            required
            v-model="args.employer.phoneNumber"
            placeholder="Contact Phone Number "
            type="tel"
            name="phoneNumber"
            class="mt-1 mb-10"
          />
          <!-- 
          <div class="mt-4">
            <label for="industry" class="text-dark-800 text-sm font-medium"
              >Select Industry
              <span class="text-xs text-error tracking-wide"> *</span>
            </label>
          </div> -->
          <easiSelectInput
            class="mt-7 mb-1"
            label="Select Industry"
            @update="args.employer.industry = $event"
            :value="args.employer.industry"
            :options="industryData"
          />

          <div
            class="flex md:flex-row flex-col justify-between gap-1 md:gap-4 w-full items-center"
          >
            <div class="w-full">
              <!-- <label for="numberOfEmp" class="text-dark-800 text-sm font-medium"
              >Number of Employees
              <span class="text-xs text-error tracking-wide"> *</span>
            </label> -->
              <easiTextInput
                required
                placeholder="Number of Employees"
                type="number"
                name="numberOfEmp"
                v-model="args.employer.companySize"
                class="mt-1 mb-5"
              />
            </div>

            <div class="w-full">
              <!-- <label for="numberOfxp" class="text-dark-800 text-sm font-medium"
              >Number of Expatriates
              <span class="text-xs text-error tracking-wide"> *</span>
            </label> -->
              <easiTextInput
                required
                placeholder="Number of Expatriates"
                v-model="numOfExpatriate"
                type="number"
                name="numberOfxp"
                class="mt-1 mb-5"
              />
            </div>
          </div>

          <!-- <label for="taxId" class="text-dark-800 text-sm font-medium"
          >Tax Identification Number
          <span class="text-xs text-error tracking-wide"> *</span>
        </label> -->
          <easiTextInput
            v-model="args.employer.tin"
            required
            placeholder="Tax Identification Number"
            type="text"
            name="taxId"
            class="mt-1 mb-10"
          />
        </div>
        <!-- <hr
          class="mt-2 mb-6 border-0.5 border-dividerColor text-dividerColor"
        /> -->

        <!-- <div class="px-3 md:px-12 lg:px-8 xl:px-10">
          <UploadComponent
            @fileUrl="uploadFile = $event"
            :info="'Upload A Letterhead Request For Company TAX ID (Optional)'"
          />
        </div> -->

        <div
          class="z-50 sticky w-full bg-white bottom-0 px-3 md:px-12 lg:px-8 xl:px-10"
        >
          <!-- <div
            @click="scrollBottom"
            class="cursor-pointer my-2 flex justify-center"
          >
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#B5B6B5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> -->
          <hr class="mb-5 border-0.5 border-dividerColor text-dividerColor" />
          <button
            class="focus:outline-none text-sm font-medium mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            Submit
          </button>
        </div>
      </form>

      <form
        @submit.prevent="registerPaye"
        v-show="!employer"
        autocomplete="on"
        class="flex flex-col text-left"
      >
        <div class="px-4 sm:px-6 lg:px-6 xl:px-6">
          <div
            class="flex md:flex-row flex-col justify-between gap-4 w-full items-center mb-0"
          >
            <div class="w-full">
              <!-- <label for="title" class="text-dark-800 text-sm font-medium"
                >Title <span class="text-xs text-error tracking-wide"> *</span>
              </label> -->

              <easiSelectInput2
                required
                placeholder="Title"
                class="mt-1 mb-1"
                @update="args.employee.title = $event"
                :value="args.employee.title"
                :autoHeight="true"
                :options="[
                  {
                    label: 'Mr',
                    value: 'MR',
                  },
                  {
                    label: 'Mrs',
                    value: 'MRS',
                  },
                  {
                    label: 'Miss',
                    value: 'MISS',
                  },
                ]"
              />
            </div>

            <div class="w-full">
              <!-- <label
                for="employmentType"
                class="text-dark-800 text-sm font-medium"
                >Gender <span class="text-xs text-error tracking-wide"> *</span>
              </label> -->

              <easiSelectInput2
                required
                class="mt-0 mb-1"
                placeholder="Gender"
                @update="args.employee.gender = $event"
                :autoHeight="true"
                :value="args.employee.gender"
                :options="[
                  {
                    label: 'Male',
                    value: 'MALE',
                  },
                  {
                    label: 'Female',
                    value: 'FEMALE',
                  },
                  {
                    label: 'Other',
                    value: 'OTHER',
                  },
                ]"
              />
            </div>
          </div>

          <!-- <label for="firstName" class="text-dark-800 text-sm font-medium"
          >First Name
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="First Name"
            type="text"
            name="firstName"
            class="mt-1 mb-5"
            v-model="args.employee.firstName"
          />

          <!-- <label for="lastName" class="text-dark-800 text-sm font-medium"
          >Last Name
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="Last Name"
            type="text"
            name="lastName"
            class="mt-1 mb-5"
            v-model="args.employee.lastName"
          />

          <!-- <label for="emailAddress" class="text-dark-800 text-sm font-medium"
          >Email Address
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="Email Address"
            type="email"
            name="emailAddress"
            class="mt-1 mb-5"
            v-model="args.employee.email"
            autocom
          />

          <!-- <label for="phoneNumber" class="text-dark-800 text-sm font-medium"
          >Phone Number
          <span class="text-xs text-error tracking-wide"> *</span>
        </label> -->
          <easiTextInput
            required
            placeholder="Phone Number"
            type="tel"
            name="phoneNumber"
            class="mt-1 mb-5"
            v-model="args.employee.phone"
          />

          <div class="mt-2 -mb-1">
            <label for="dob" class="text-dark-600 text-xs font-medium"
              >Date of Birth
              <span class="text-xs text-error tracking-wide"> *</span></label
            >
          </div>
          <!-- <easiDate></easiDate> -->
          <input
            class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-2xl py-3 px-4 block appearance-none rounded-2xl mt-1 mb-2"
            type="date"
            required
            name="dob"
            :max="today"
            v-model="args.employee.dob"
            id="dob"
          />

          <easiSelectInput2
            required
            class="mt-6"
            placeholder="Marital status"
            @update="args.employee.relationshipStatus = $event"
            :value="args.employee.relationshipStatus"
            :autoHeight="true"
            :options="[
              { label: 'Single', value: 'SINGLE' },
              { label: 'Married', value: 'MARRIED' },
              { label: 'Divorced', value: 'DIVORCED' },
            ]"
          />

          <!-- <label for="industry" class="text-dark-800 text-sm font-medium"
          >Business Sector
          <span class="text-xs text-error tracking-wide"> *</span>
        </label>
        <easiSelectInput
          class="mt-1 mb-5"
          @update="args.industry = $event"
          :value="args.industry"
          :options="industryData"
        /> -->

          <!-- <label
          for="residentialAddress"
          class="text-dark-800 text-sm font-medium"
          >Residential Address
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="Residential Address"
            type="text"
            name="residentialAddress"
            class="mt-2"
            v-model="args.employee.address"
          />

          <div
            class="flex flex-col md:flex-row mt-4 justify-between gap-1 md:gap-4 w-full items-center"
          >
            <div class="w-full">
              <easiSelectInput2
                class="mt-1"
                @update="updateLg($event)"
                placeholder="Residential state"
                :value="args.employee.state"
                :options="statesData"
              />
            </div>

            <div class="w-full">
              <easiSelectInput2
                class="mt-1"
                @update="args.employee.lga = $event"
                placeholder="Local Government Area"
                :value="args.employee.lga"
                :options="localGovtData"
              />
            </div>
          </div>

          <div
            class="flex flex-col md:flex-row justify-between md:gap-4 gap-1 w-full items-center mt-4"
          >
            <div class="w-full">
              <easiSelectInput2
                class="mt-1"
                placeholder="Nationality"
                @update="args.employee.country = $event"
                :value="args.employee.country"
                :options="countryData"
              />
            </div>

            <div class="w-full -mt-5">
              <!-- <label for="occupation" class="text-dark-800 text-sm font-medium"
              >Occupation
              <span class="text-xs text-error tracking-wide"> *</span>
            </label> -->
              <easiTextInput
                required
                placeholder="occupation"
                v-model="args.employee.occupation"
                type="text"
                name="occupation"
                class="mt-0"
              />
            </div>
          </div>

          <!-- <label for="nin" class="text-dark-800 text-sm font-medium"
          >NIN <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="NIN"
            type="text"
            name="nin"
            class=""
            v-model="args.employee.nin"
          />

          <!-- <label for="bvn" class="text-dark-800 text-sm font-medium"
          >BVN <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="BVN"
            type="text"
            name="bvn"
            class=""
            v-model="args.employee.bvn"
            :maxlength="11"
          />

          <!-- <label for="companyName" class="text-dark-800 text-sm font-medium"
          >Company Name
          <span class="text-xs text-error tracking-wide"> *</span></label
        > -->
          <easiTextInput
            required
            placeholder="Company Name"
            type="text"
            name="companyName"
            class="mt-1 mb-1"
            v-model="args.employer.companyName"
          />
        </div>

        <!-- <hr class="mt-2 mb-5 border-0.5 border-dividerColor text-dividerColor" /> -->
        <div class="px-4 sm:px-6 lg:px-6 xl:px-6">
          <!-- <label for="taxId" class="text-dark-800 text-sm font-medium"
          >LASSRA Number <span class="text-grey"> (Optional)</span>
        </label> -->
          <easiTextInput
            placeholder="LASSRA Number"
            type="number"
            name="taxId"
            class="mt-1 mb-5"
            v-model="args.employee.lassraNo"
          />
        </div>
        <!-- <hr class="mb-5 border-0.5 border-dividerColor text-dividerColor" /> -->
        <div
          class="z-50 sticky w-full bg-white bottom-0 px-3 md:px-12 lg:px-8 xl:px-10"
        >
          <!-- <div
            @click="scrollBottom"
            class="cursor-pointer my-2 flex justify-center"
          >
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#B5B6B5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> -->
          <hr class="mb-5 border-0.5 border-dividerColor text-dividerColor" />
          <button
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            Submit
          </button>
        </div>
      </form>
    </div>

    <easiModal v-if="agreement" @close="agreement = false">
      <template v-slot:header>
        <div class="flex items-center gap-5">
          <img src="@/assets/icons/agreement-icon.svg" alt="" />
          <h1 class="text-dark-800 font-bold">Agreement</h1>
        </div>
      </template>

      <form>
        <div
          class="flex flex-col md:flex-row justify-between px-4 sm:px-10 lg:px-6 xl:px-8 items-start gap-y-4 md:gap-y-0 md:items-center gap-x-10"
        >
          <div class="flex gap-2 justify-center">
            <div class="p-0.5 bg-priBorder"></div>
            <span class="text-sm text-left text-dark-800 font-bold"
              >Do you accept Alausa, Ikeja tax office as your preferred tax
              office for filing your PAYE returns?
            </span>
          </div>
          <div
            class="flex justify-start md:justify-center items-start md:items-center gap-x-2 mb-5"
          >
            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium"
                >Yes
              </label>
              <input
                :checked="useTaxOffice"
                :value="true"
                @change="useTaxOffice = true"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium">No</label>
              <input
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
                :checked="!useTaxOffice"
                :value="false"
                @change="useTaxOffice = false"
              />
            </div>
          </div>
        </div>

        <div
          v-if="useTaxOffice === false"
          class="px-4 sm:px-10 lg:px-6 xl:px-8 text-left md:mt-7 mb-4"
        >
          <label for="" class="text-dark-800 text-sm font-medium"
            >Enter Preferred Tax Office</label
          >

          <easiSelectInput2
            required
            class="mt-1 mb-3"
            @update="args.taxOffice = $event"
            :value="args.taxOffice"
            :options="[
              {
                label: 'Ikeja Tax Office',
                value: 'Ikeja Tax Office',
              },
              {
                label: 'Federal Inland Revenue Ikoyi',
                value: 'Federal Inland Revenue Ikoyi',
              },
              {
                label: 'Jebba Tax Office',
                value: 'Jebba Tax Office',
              },
            ]"
          />
        </div>

        <div
          class="flex flex-col md:flex-row justify-between px-4 sm:px-10 lg:px-6 xl:px-8 items-start gap-y-4 md:gap-y-0 md:items-center gap-x-10"
        >
          <div class="flex gap-2 justify-center">
            <div class="p-0.5 bg-goldBorder"></div>
            <span class="text-sm text-left text-dark-800 font-bold"
              >Is this employee an expatriate?
            </span>
          </div>
          <div class="flex justify-center items-center gap-x-2 mb-5">
            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium"
                >Yes</label
              >
              <input
                :checked="isExpatriate"
                :value="true"
                @change="isExpatriate = true"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium">No</label>
              <input
                :checked="!isExpatriate"
                :value="false"
                @change="isExpatriate = false"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>
          </div>
        </div>

        <div
          class="flex flex-col md:flex-row justify-between px-4 sm:px-10 lg:px-6 xl:px-8 items-start gap-y-4 md:gap-y-0 md:items-center gap-x-10"
        >
          <div class="flex gap-2 justify-center">
            <div class="p-0.5 bg-hoverColor"></div>
            <span class="text-sm text-left text-dark-800 font-bold"
              >Do you have an existing taxpayer ID?
            </span>
          </div>
          <div
            class="flex justify-start md:justify-center md:self-center items-start md:items-center gap-x-2 mb-5"
          >
            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium"
                >Yes</label
              >
              <input
                :checked="hasTaxPayerId"
                :value="true"
                @change="hasTaxPayerId = true"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div class="flex items-center">
              <label for="" class="text-dark-800 text-sm font-medium"
                >No
              </label>
              <input
                :checked="!hasTaxPayerId"
                @change="hasTaxPayerId = false"
                :value="false"
                class="mx-4 my-2"
                type="checkbox"
                name=""
                id=""
              />
            </div>
          </div>
        </div>

        <div
          v-if="hasTaxPayerId === true"
          class="px-4 sm:px-10 lg:px-6 xl:px-8 text-left mt-4 mb-4"
        >
          <!-- <label for="" class="text-dark-800 text-sm font-medium"
            >Enter The Taxpayer ID</label
          > -->

          <easiTextInput
            required
            placeholder="Enter The Taxpayer ID"
            type="number"
            name="taxId"
            class="mt-1 mb-5"
          />
        </div>

        <hr class="mb-5 border-0.5 border-dividerColor text-dividerColor" />
        <button
          type="button"
          class="focus:outline-none text-sm mt-2 py-3 w-11/12 px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </button>
      </form>
    </easiModal>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>

    <!-- <easiModal
      v-if="showOptions || goBack"
      @close="$emit('close')"
      :isHeader="false"
    >
      <div class="p-2 w-full text-center flex flex-col gap-y-2">
        <h1 class="font-medium uppercase font-bold">PAYE Registration</h1>
        <span class="text-center text-bodyDark text-xs"
          >Kindly select your registration option</span
        >

        <div
          class="cursor-pointer border border-outlineGray p-3 w-11/12 rounded-xl flex flex-col gap-y-3 mt-5 self-center"
        >
          <div
            v-for="type in ['company', 'employee']"
            :key="type"
            @click="
              () => {
                type === 'company' ? (employer = true) : (employer = false);
                isChosen = true;
                showOptions = false;
                regType = type;
              }
            "
            class="bg-highlight border border-outlineGray flex justify-between rounded-lg p-2"
          >
            <span class="capitalize text-sm font-bold">
              {{ type }} Registration</span
            >

            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.875 16.25L13.125 10L6.875 3.75"
                stroke="#0F172A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </easiModal> -->
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const props = defineProps({
  goBack: {
    type: Boolean,
    default: false,
  },
});

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj, processDate } = helperFunctions;
const adminData = computed(() => store.getCompanyAdmin);
const watchProp = computed(() => props.goBack);
const backBool = ref(false);
const getCompanyKyc = computed(() =>
  store.getCompanyKyc && store.getCompanyKyc.data
    ? store.getCompanyKyc.data
    : {}
);
watch(watchProp, (val) => {
  backBool.value = val;
});

const route = useRoute();
const emit = defineEmits(["registered", "back", "close"]);
const employeeIds = ref([]);
const registeredEmployee = ref(true);
const employer = ref(true);
const companyData = computed(() => store.getCompanyDetails);
const loading = ref(false);
const updateSuccess = ref(false);
const isChosen = ref(false);
const showOptions = ref(true);
const successMessage = ref("");
const numOfExpatriate = ref("");
const regType = ref("");
const args = ref({
  employeeId: "",
  employer: {
    website: "",
    tin: "",
    state: "",
    registered: true,
    proofOfAddress: "",
    phoneNumber: "",
    payeState: "",
    payeID: "",
    nhfCode: "",
    logo: "",
    industry: "",
    employerPensionCode: "",
    email: "",
    country: "",
    companySize: "",
    companyName: "",
    cacNumber: "",
    cacDocument: "",
    address: "",
  },
  employee: {
    title: "",
    taxOffice: "",
    taxId: "",
    state: "",
    religion: "",
    relationshipStatus: "SINGLE",
    phone: "",
    otherPhoneNumbers: [],
    occupation: "",
    nin: "",
    middleName: "",
    lga: "",
    lastName: "",
    lassraNo: "",
    gender: "",
    firstName: "",
    expariate: false,
    email: "",
    dob: "",
    country: "",
    bvn: "",
    address: "",
  },
});

function showEmployeeForm() {
  employer.value = false;
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function scrollBottom() {
  window.scrollTo({ top: 600, behavior: "smooth" });
}

const fetchCompanyAdminDetails = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });
  if (adminData.value && Object.keys(adminData).length) {
    args.value.employer.phoneNumber =
      adminData.value.companyAdmin && adminData.value.companyAdmin.phoneNumber
        ? adminData.value.companyAdmin.phoneNumber
        : "";
    args.value.employer.email =
      adminData.value.companyAdmin && adminData.value.companyAdmin.email
        ? adminData.value.companyAdmin.email
        : "";
  }
  console.log("Company Admin Details");
  // log(adminDetails);
};

fetchCompanyAdminDetails();

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });

  if (companyData && Object.keys(companyData.value).length) {
    args.value.employer.website =
      companyData.value.company && companyData.value.company.website
        ? companyData.value.company.website
        : "";

    args.value.employer.companyName =
      companyData.value.company && companyData.value.company.companyName
        ? companyData.value.company.companyName
        : "";
    // args.value.employer.companySize =
    //   companyData.value.company && companyData.value.company.companySize
    //     ? companyData.value.company.companySize
    //     : "";
    args.value.employer.address =
      companyData.value.company && companyData.value.company.address
        ? companyData.value.company.address
        : "";
    args.value.employer.industry =
      companyData.value.company && companyData.value.company.industry
        ? companyData.value.company.industry
        : "";
    args.value.employer.tin =
      companyData.value.company && companyData.value.company.tin
        ? companyData.value.company.tin
        : "";
    console.log(companyData.value);
  }
}
const queryCompanyKyc = async () => {
  // loading.value = true;
  try {
    const res = await query({
      endpoint: "GetCompanyKycInformation",
      payload: {},
      service: "SETTINGS",
      storeKey: "companyKyc",
    });
    if (getCompanyKyc.value && getCompanyKyc.value.companyAccountInformation) {
      args.value.employer.cacNumber =
        getCompanyKyc.value.companyAccountInformation.cacNumber;
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};
queryCompanyKyc();
queryCompany();

const optionTypes = computed(() => {
  const data = ["company", "employee"];

  if (companyData.value && companyData.value.registrationType === "PERSONAL") {
    return ["employee"];
  }
  return data;
});

const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.employee.state);
const agreement = ref(false);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);

const allEmployees = computed(() => getAllEmployees.value.data);

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

function updateEmp(e) {
  args.value.employeeId = e[0];
  const employee = allEmployees.value.find((emp) => emp._id === e[0]);
  const foundEmp = employee.profile;

  let payload = Object.assign({}, args.value.employee);

  for (let k of Object.keys(payload)) {
    if (
      Object.keys(foundEmp).includes(k) &&
      foundEmp[k] != undefined &&
      foundEmp[k] != null &&
      foundEmp[k]
    ) {
      args.value.employee[k] = foundEmp[k];
    }
  }
  args.value.employee.occupation = employee.job.jobTitle;
  console.log(args.value.employee);
}

const countryData = computed(() => {
  let countryArray = [];
  countryArray = Countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });
  return countryArray;
});
watch(
  selectedState,
  (value, newValue) => {
    console.log(value, "New Value");
    const lgs = lgData.find(
      (lg) => lg.state.toLowerCase() === value.toLowerCase()
    );
    localGovtData.value =
      lgs &&
      lgs.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  },
  { deep: true }
);
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  console.log(stateArray);
  return stateArray;
});
const today = ref("");
today.value = processDate();

function handleType(type) {
  showOptions.value = false;
  type === "company" ? (employer.value = true) : (employer.value = false);

  isChosen.value = true;
  regType.value = type;
  backBool.value = false;
  emit("back", backBool.value);
}
function updateLg(event) {
  args.value.employee.state = event;
  console.log(event);
  // args.value.employee.taxOfficestate = event;
}
function closeFN() {
  emit("registered");
}
async function registerPaye() {
  if (
    args.value.employee &&
    args.value.employee.title &&
    args.value.employee.title.length &&
    args.value.employee.bvn.length !== 11
  ) {
    toast.error("BVN length must be 11 characters");
    return false;
  }
  loading.value = true;

  // args.cacDocument = await uploadFileToServer(uploadFile.value);
  // args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);

  if (
    args.value.employer["website"] &&
    !args.value.employer["website"].includes("http")
  ) {
    args.value.employer["website"] = `https://${args.value.employer.website}`;
  }
  let payload = {
    employer: {},
    employee: {},
    employeeId: "",
  };
  let payloadArr = [];
  try {
    if (employer.value === true) {
      payload.employer = args.value.employer;
    } else {
      // payload.employee = args.value.employee;
      // payload.employeeId = args.value.employeeId;
      payloadArr.push({
        employeeId: args.value.employeeId,
        profile: args.value.employee,
      });
    }
    let res = await mutate({
      endpoint:
        employer.value === true
          ? "RegisterPayeCompliance"
          : "RegisterPayeEmployee",
      data: {
        input:
          employer.value === true ? { employer: payload.employer } : payloadArr,
      },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      await queryCompany();
      updateSuccess.value = true;
      successMessage.value = res.message;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    toast.error(e);
    loading.value = false;
  }
}
</script>

<style scoped>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}

.boxHeight {
  height: 600px;
}
</style>
