<template>
  <div class="w-full" v-if="transDetails">
    <div class="flex w-full gap-1 md:gap-x-3">
      <div class="w-9/12">
        <ComplianceSearch placeholder="Search service, date..." v-model="searchText" @search="searchData" />
      </div>

      <!-- /////// Filter Component Start /////// -->
      <div class="md:ml-0 mb-3 z-50">
        <Filter :transaction="true" ref="viewEmployeeFilterRef" @update="updateData($event)" />
      </div>

      <div class="md:ml-0 mb-3 z-20">
        <ComplianceActions @act="($event) => queryComplianceTransaction($event)" />
      </div>
    </div>
    <hr class="border-0.5 border-outlineGray mb-2" />
    <!-- <easiActiveFilter @close="removeFilter" :filters="activeFilters" /> -->
    <div
      v-for="trx in visibleData"
      :key="trx.datePaid"
      @click="
        $router.push({
          name: 'ViewSingleTransactionHistory',
          query: { type: trx.name, createdAt: trx.datePaid },
          params: { id: trx.name },
        })
      "
      class="cursor-pointer bg-white w-full mb-2 shadow-md md:py-4 md:px-5 p-2 rounded-lg flex justify-between items-center"
    >
      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Service Type</span>
        <p class="text-bodyDark text-sm uppercase">{{ trx.name }}</p>
      </div>

      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize hidden md:block">Total Amount Paid</span>
        <span class="text-xs text-newGray capitalize md:hidden">Amount Paid</span>
        <p class="text-bodyDark text-sm">
          {{ formatAmount(trx.totalAmountPaid) }}
        </p>
      </div>

      <div class="hidden md:flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Total staff paid</span>
        <p class="text-bodyDark text-sm">{{ trx.totalStaffPaid }}</p>
      </div>

      <div class="flex flex-col gap-3">
        <span class="text-xs text-newGray capitalize">Date Paid</span>
        <p class="text-bodyDark text-sm">{{ getDateText(trx.datePaid) }}</p>
      </div>
    </div>

    <hr class="border-0.5 border-dividerColor w-full mt-2" />

    <div
      v-if="$route.name === 'ViewComplianceHistory'"
      class="flex w-full items-center justify-end self-end md:justify-start"
    >
      <easiPagin
        :data="transDetails"
        @page:update="updatePage"
        :currentPage="currentPage"
        :visibleData="visibleData"
        :pageSize="pageSize"
        :fromCompliance="true"
        class="w-full mt-2"
      />
    </div>
    <div v-else class="flex w-full justify-end mt-3 items-center">
      <p @click="$router.push({ name: 'ViewComplianceHistory' })" class="cursor-pointer text-primary text-xs">
        VIEW ALL COMPLIANCES HISTORY <span class="text-lg">></span>
      </p>
    </div>
  </div>
  <div v-else class="text-center my-10 flex flex-col justify-center">
    <div class=" ">
      <div class="container mx-auto flex items-center justify-center gap-4 flex-wrap">
        <img src="@/assets/img/Validation.png" class="h-32" alt="" />
      </div>
      <h3 class="text-xl text-dark-800 font-bold">No History !</h3>
      <p class="text-dark-500 text-sm">You have not made any compliance transaction</p>
    </div>
  </div>
  <easiLoading v-if="loading" />
</template>

<script setup>
import { ref, computed, onMounted, watch, watchEffect } from "vue";
import { useDataStore } from "@/stores/data.js";

import { helperFunctions } from "@/composable/helperFunctions";
import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import Filter from "@/components/Filters/ComplianceFilter.vue";
import ComplianceActions from "@/components/Compliance/ComplianceActions.vue";

const { formatDateString, formatAmount, getDateText, downloadBase64 } = helperFunctions;
const hasData = true;

const store = useDataStore();
const { query } = store;
const loading = ref(false);
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);
const filterData = ref({
  to: null,
  names: null,
  from: null,
});

// watch(
//   filterData,
//   async () => {
//     await queryComplianceTransaction();
//     updateVisibleData();
//   },
//   { deep: true }
// );
const sortMostRecent = (data) => {
  return data.sort((a, b) => new Date(b.datePaid) - new Date(a.datePaid));
};
const transDetails = computed(() => {
  return store.getSingleComplianceTransaction &&
    Array.isArray(store.getSingleComplianceTransaction) &&
    store.getSingleComplianceTransaction.length
    ? sortMostRecent(store.getSingleComplianceTransaction)
    : [];
});
const updateVisibleData = () => {
  let datas = (transDetails.value && Array.isArray(transDetails.value) && transDetails.value) || [];
  visibleData.value =
    (datas && datas.slice(currentPage.value * pageSize.value, currentPage.value * pageSize.value + pageSize.value)) ||
    [];

  // if we have 0 visible todos, go back a page
  if (visibleData.value && visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
function openPdfInNewTab(base64Pdf) {
  // Create a Blob from the base64 PDF string
  const pdfData = atob(base64Pdf);
  const arrayBuffer = new ArrayBuffer(pdfData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < pdfData.length; i++) {
    uint8Array[i] = pdfData.charCodeAt(i);
  }
  const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

  // Generate a URL for the Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Open the PDF in a new browser tab
  const newTab = window.open(pdfUrl, "_blank");

  // Trigger print dialog when new tab is loaded
  if (newTab) {
    newTab.onload = function () {
      newTab.print();
    };
  }
}

const queryComplianceTransaction = async (arg) => {
  console.log(arg, "tye");
  loading.value = true;
  let payload = {
    input: filterData.value,
    download: arg && arg.value ? true : false,
  };
  if (arg && arg.value) {
    payload["type"] = arg.value;
  }
  try {
    const res = await query({
      endpoint: "GetComplianceTransactions",
      payload,
      service: "PAYROLL",
      storeKey: arg && arg.value ? null : "listComplianceTransaction",
    });
    if (res.success) {
      if (arg.name === "Print") {
        openPdfInNewTab(res.data.data);
      } else {
        downloadBase64(res.data.data, res.data.fileName, arg.value === "CSV" ? "excel" : null);
      }
    }

    // console.log(transDetails.value, "trans dets");
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};
// queryComplianceTransaction();

const searchText = ref("");

function analyzeSearchText(searchText) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Check if the search text partially matches a month
  const partialMonthMatch = monthNames.some((month) => month.toLowerCase().includes(searchText.toLowerCase()));
  if (partialMonthMatch) {
    return "Partial Month";
  }

  // Check if the search text partially matches a date (assuming the date is in the "YYYY-MM-DD" format)
  if (/^\d{4}-\d{0,2}-\d{0,2}$/.test(searchText) || /\d/.test(searchText)) {
    return "Partial Date";
  }

  // If it's not a partial month or date, consider it as regular text
  return "Regular Text";
}

function searchArrayObjects(arr, searchString) {
  if (analyzeSearchText(searchString) === "Regular Text") {
    return arr.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      );
    });
  }
  return arr.filter((item) => {
    return (
      item.datePaid.toLowerCase().includes(searchString) ||
      getDateText(item.datePaid).toLowerCase().includes(searchString)
    );
  });
}

function searchData(e) {
  searchText.value = e.toLowerCase();
  if (searchText.value && searchText.value !== undefined && searchText.value.length) {
    visibleData.value = transDetails.value;
    visibleData.value = searchArrayObjects(transDetails.value, searchText.value);
  } else {
    visibleData.value = transDetails.value;
  }
}

async function updateData(obj) {
  console.log(obj);
  filterData.value = obj;
  await queryComplianceTransaction();
  updateVisibleData();
}

onMounted(async () => {
  if (!transDetails.value || !transDetails.value.length) {
    await queryComplianceTransaction();
  }
  updateVisibleData();
});
</script>

<style></style>
